import { CheckoutAddressFormProps } from '../types'

/**
 * Locale-specific definition of the fields that should appear in the CheckoutAddressForms.
 * Field labels are currently hardcoded and should be moved to ToniesUI language files with TWAS-1868.
 */
export type FieldsType = Record<string, CheckoutAddressFormProps['fields']>

export const fields: FieldsType = {
  'de-de': [
    {
      name: 'country',
      label: 'Land',
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'firstName',
      label: 'Vorname',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'lastName',
      label: 'Nachname',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'company',
      label: 'Firma',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'streetName',
      label: 'Straße',
      maxLength: 100,
      isRequired: true,
      gridWidthNarrow: 0.75,
    },
    {
      name: 'streetNumber',
      label: 'Hausnummer',
      maxLength: 10,
      isRequired: true,
      gridWidthNarrow: 0.25,
      hasNewLine: true,
    },
    {
      name: 'additionalStreetInfo',
      label: 'Adresszusatz',
      maxLength: 50,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'postalCode',
      label: 'PLZ',
      maxLength: 20,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'city',
      label: 'Ort',
      maxLength: 35,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'phone',
      label: 'Telefonnummer',
      type: 'tel',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'email',
      label: 'E-Mail',
      type: 'email',
      maxLength: 80,
      isRequired: true,
      hasNewLine: true,
    },
  ],
  'en-eu': [
    {
      name: 'country',
      label: 'Country',
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'firstName',
      label: 'First Name',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'company',
      label: 'Company Name',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'streetName',
      label: 'Address',
      maxLength: 100,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'additionalStreetInfo',
      label: 'Additional info',
      maxLength: 50,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'postalCode',
      label: 'Postcode',
      maxLength: 20,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'region',
      label: 'County',
      maxLength: 30,
      isRequired: false,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'city',
      label: 'City',
      maxLength: 35,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      maxLength: 80,
      isRequired: true,
      hasNewLine: true,
    },
  ],
  'en-gb': [
    {
      name: 'country',
      label: 'Country',
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'firstName',
      label: 'First Name',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'streetName',
      label: 'Address',
      maxLength: 100,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'additionalStreetInfo',
      label: 'Additional info',
      maxLength: 50,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'company',
      label: 'Company Name',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'city',
      label: 'City',
      maxLength: 35,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'region',
      label: 'County',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'postalCode',
      label: 'Postcode',
      maxLength: 20,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'tel',
      maxLength: 30,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      maxLength: 80,
      isRequired: true,
      hasNewLine: true,
    },
  ],
  'fr-fr': [
    {
      name: 'country',
      label: 'Pays',
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'firstName',
      label: 'Prénom',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'lastName',
      label: 'Nom',
      maxLength: 30,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'streetName',
      label: 'Adresse (numéro et nom de rue)',
      maxLength: 60,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'additionalStreetInfo',
      label: 'Complément d’adresse',
      maxLength: 35,
      isRequired: false,
      hasNewLine: true,
    },
    {
      name: 'postalCode',
      label: 'Code Postal',
      maxLength: 20,
      isRequired: true,
      gridWidthNarrow: 0.5,
    },
    {
      name: 'city',
      label: 'Ville',
      maxLength: 35,
      isRequired: true,
      gridWidthNarrow: 0.5,
      hasNewLine: true,
    },
    {
      name: 'phone',
      label: 'Téléphone',
      type: 'tel',
      maxLength: 30,
      isRequired: true,
      hasNewLine: true,
    },
    {
      name: 'email',
      label: 'E-mail',
      type: 'email',
      maxLength: 80,
      isRequired: true,
      hasNewLine: true,
    },
  ],
}
