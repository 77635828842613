import React from 'react'
import { Paragraph } from '@boxine/tonies-ui'
import {
  SupportedParagraphHTMLTags,
  SupportedSizes,
} from '@boxine/tonies-ui/dest/types/src/atoms/Typography/types'
import { fields } from '../../../../components/molecules/CheckoutAddressForm/data/fields'
import { useLocaleAltNotation } from '../../../../providers/locale/altNotation'
import { BxAddress } from '../../../../lib/commercetools/requests/bxTypes'
import { useBillingCountries } from '../../../../hooks/useBillingCountries'
import { AddressType } from '../../../../types/normalize/cart'

export const ReadOnlyAddress = ({
  address,
  size = 2,
  type = undefined,
  asHTMLTag,
}: {
  address: BxAddress
  size?: SupportedSizes
  type?: AddressType
  asHTMLTag?: SupportedParagraphHTMLTags
}) => {
  const locale = useLocaleAltNotation('lc-cc')
  const { countries } = useBillingCountries()

  const outputString = fields[locale].map((field, index) => {
    let value = address[field.name]

    if (!value) return null

    if (field.name === 'country') {
      const countryLabel = countries.find(
        ({ value: countryValue }) => countryValue === value
      )?.label

      if (countryLabel) value = countryLabel
    }

    return (
      <span
        key={`${field.name}-${index}`}
        data-testid={`${type}-readOnly-${field.name}`}
      >
        {!field.hasNewLine ? (
          <>
            {/* value + seperator (' ') */}
            {`${value} `}
          </>
        ) : (
          <>
            {value}
            <br />
          </>
        )}
      </span>
    )
  })

  return (
    <Paragraph size={size} asHTMLTag={asHTMLTag}>
      {outputString}
    </Paragraph>
  )
}
