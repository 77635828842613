import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ hasVisual: boolean }>`
  ${({ hasVisual }) =>
    hasVisual &&
    css`
      position: relative;
      padding-left: 2rem;
    `}
`
