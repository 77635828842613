import React, { ReactNode } from 'react'
import { media } from '@boxine/tonies-ui'
import styled from 'styled-components'

const Svg = styled.svg`
  width: 15rem;
  height: 5.5rem;

  ${media.tablet`
    height: 6rem;
    width: 19rem;
  `}
`

const CodeBoxWrapper = styled.span`
  position: relative;
  display: inline-grid;
`

const ReferralCodeWrapper = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  width: fit-content;
`

type CodeFieldViewProps = {
  children: ReactNode
}

export const CodeFieldView = ({ children }: CodeFieldViewProps) => {
  return (
    <CodeBoxWrapper>
      <Svg viewBox="0 0 304 103">
        <g fill="none" fillRule="evenodd">
          <path
            d="M295.212 48.707c16.051-15.43 7.965-32.308-26.88-38.54-39.18-7.007-54.215.779-67.883 4.672-13.668 3.892-25.119 3.016-53.592-4.77C118.382 2.285 67.127-.634 46.627 4.23 26.128 9.096.448 12.892 4.092 34.692c3.577 21.393-14.411 48.37 8.368 51.29 22.779 2.92 52.391 6.812 79.727 13.625 27.335 6.813 75.489-10.71 108.263-4.185 46.926 9.343 59.793-2.701 78.818-16.74 20.045-14.793-2.28-12.457 15.945-29.975"
            fill="#FEFEFE"
          />
          <g transform="matrix(-1 0 0 1 303.763 .065)">
            <path
              d="M301.89 77.625c-1.44 4.928-5.74 7.099-11.326 7.894-15.87 2.26-31.774 4.18-47.544 6.95-8.009 1.408-15.984 2.97-23.888 4.762-6.725 1.524-13.096 3.17-20.092 3.392-27.427.875-54.074-7.816-81.548-7.192-14.346.325-28.015 4.61-42.443 4.749-10.809.104-21.336-1.835-30.639-6.635-8.304-4.284-16.382-10.164-23.168-16.066-3.164-2.751-5.752-5.719-5.078-9.715.377-2.239.987-4.248.437-6.524-1.01-4.17-4.502-7.747-7.681-10.863C1.983 41.564-1.903 32.36 4.767 24.325c8.034-9.678 24.008-12.819 36.8-14.735C54.672 7.628 68.24 6.875 81.35 9.21c7.599 1.354 14.642 3.963 21.977 6.03 22.59 6.366 44.436-3.43 66.265-7.62 13.697-2.628 27.726-4.104 41.727-4.87 12.272-.67 24.79-.912 37.014.44 10.602 1.172 21.763 3.919 31.676 7.283 11.08 3.762 20.039 10.282 19.443 21.281-.303 5.576-.992 10.806-.328 16.419.635 5.382 1.79 10.702 2.626 16.06.677 4.35 1.403 9.07.141 13.392m-2.14-34.795c-.185-5.33 1.51-10.694.422-15.987-.81-3.94-3.065-7.475-6.405-10.268-7.82-6.54-19.482-8.928-29.718-11.28-10.648-2.444-20.9-3.785-31.953-3.954-14.417-.219-28.895.674-43.173 2.355-14.062 1.653-27.308 4.697-40.881 8.126-12.003 3.03-24.936 6.077-37.522 4.103-8.292-1.301-15.926-4.646-24.022-6.571C74.883 6.592 62.78 6.322 50.846 7.459 33.286 9.13-6.364 15.55.873 38.097c1.308 4.08 4.092 7.65 7.308 10.81 3.704 3.63 8.033 8.04 7.583 13.182-.206 2.35-1.254 4.533-.611 6.912 1.017 3.76 4.862 6.748 8.073 9.215 3.806 2.92 7.684 5.803 11.663 8.549 7.268 5.015 15.241 9.091 24.404 10.959 13.498 2.753 27.816 1.154 41.188-1.318 29.58-5.47 58.413 3.117 87.88 4.944 6.02.373 12.137.465 18.117-.345 7.618-1.032 15.05-3.352 22.553-4.882 17.785-3.629 35.824-6.261 53.891-8.628 5.188-.68 11.721-.644 15.997-3.661 3.066-2.166 4.227-5.484 4.56-8.76 1.095-10.73-3.357-21.527-3.73-32.244"
              fill="#151616"
            />
          </g>
        </g>
      </Svg>
      <ReferralCodeWrapper>{children}</ReferralCodeWrapper>
    </CodeBoxWrapper>
  )
}
