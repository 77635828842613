import React from 'react'
import { Button, Icon } from '@boxine/tonies-ui'
import styled, { useTheme } from 'styled-components'
import { Wrapper } from './components/Wrapper'
import { KeyvisualReferral } from './components/KeyvisualReferral'
import { CodeFieldView } from './components/View'
import { useReferralCode } from '../../../hooks/useReferralCode'

const StyledButton = styled(Button)`
  color: ${props => props.theme.colors.darkergrey};
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  white-space: nowrap;

  svg {
    margin-right: 0.25rem;
  }
`

type CodeFieldProps = {
  hasVisual?: boolean
}

export const ReferralCode = ({ hasVisual = false }: CodeFieldProps) => {
  const theme = useTheme()
  const { code, handleCopyToClipboard } = useReferralCode()

  return (
    <Wrapper hasVisual={hasVisual}>
      {hasVisual && <KeyvisualReferral height="9rem" />}
      <CodeFieldView>
        <StyledButton variant="secondary" onClick={handleCopyToClipboard}>
          <Icon
            type="copyToClipboard"
            height={16}
            width={16}
            fill={theme.colors.primary}
          />
          {code}
        </StyledButton>
      </CodeFieldView>
    </Wrapper>
  )
}
